.modal-view-size {
  width: 40vw;
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  margin: 10px;
}
.modal {
  &-container-size {
    width: 630px;
    height: 58px;
    padding: 20px;
  }
  &-user-icon {
    width: 30px;
    height: 30px;
    // margin-right: 8px;
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  &-cross-icon {
    width: 15px;
    height: 15px;
  }
  &-image {
    width: 55px;
    height: 55px;
    border-radius: 50px;
  }
  &-data-view {
    height: 230px;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px;
  }
  &-radio-outline {
    width: 19px;
    height: 19px;
    border: 1.5px solid #5cb555;
    border-radius: 10px;
  }
  &-radio-filled {
    width: 19px;
    height: 19px;
    background: #5cb555;
    border-radius: 10px;
  }
}
