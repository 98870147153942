.header {
  &-container {
    height: 65px;
    width: 100%;
    background: white;
    box-shadow: 2px 0px 6px 2px rgba(198, 198, 198, 0.5);
    z-index: 4;
  }

  &-bell {
    height: 26px;
    width: 22px;

    &-bubble {
      height: 16px;
      width: 16px;
      border-radius: 500px;
      background: #5cb555;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }
  &-signout-button-radius {
    border-radius: 10px;
  }
  &-profile-image {
    border-radius: 25px;
    height: 50px;
    width: 50px;
    cursor: pointer;
  }

  &-notification {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background: #ffffff;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
    padding: 0px 16px;
    overflow-y: auto;

    &-container {
      height: 400px;
      width: 260px;
      position: absolute;
      top: calc(100% + 15px);
    }

    &-item {
      height: auto;
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-border {
        @extend .header-notification-item;
        border-bottom: solid 1px #aab7c4;
      }
    }

    &-icon {
      height: 15px;
      width: 15px;
      object-fit: contain;
      object-position: center;
      margin-right: 5px;
    }

    &-bubble {
      height: 8px;
      width: 8px;
      border-radius: 8px;
      background: #5cb555;
      margin-left: 5px;
    }
  }
}

@media only screen and (max-width: 900px) {
  .header-profile-image {
    width: 35px;
    height: 35px;
  }
}
