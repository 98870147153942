.project-application-information-switch-group {
  display: flex;
  flex-direction: row;
  border-radius: 10px;
  margin-left: 10px;
  border: 1px solid #5cb555;
  width: 90px;
  height: 35px;
}

.project-application-information-switch-active {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background-color: #5cb555;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.project-application-information-switch-inactive {
  width: 50%;
  height: 100%;
  border-radius: 10px;
  background-color: transparent;
  color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.project-application-information-dropdown {
  width: 345px;
  max-width: 350px;
  height: 50px;
  border-radius: 10px;
  padding: 1%;
  margin-top: 10px;
  border: 1px solid #dadada;
  outline: none;
}
.project-application-information-textarea {
  width: 100%;
  height: 100px;
  margin-top: 10px;
  border-radius: 10px;
  resize: none;
  border: 1px solid #dadada;
  outline: none;
}

.project-application-information-padding {
  padding-left: 2%;
}
#buildingIdState option {
  width: 50px;
}
