.react-calendar {
  /* width: 350px; */
  max-width: 100%;
  background: white;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #e6e6e6;
}
.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__month-view__days__day--weekend {
  color: #d10000;
}
.react-calendar__month-view__days__day--neighboringMonth {
  color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
  flex-shrink: 0;
}
.react-calendar__tile:disabled {
  background-color: #f0f0f0;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  /* background-color: #e6e6e6; */
}
.react-calendar__tile--now {
  background: #ffff76;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: #ffffa9;
}
.react-calendar__tile--hasActive {
  background: #76baff;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #a9d4ff;
  color: white;
}
.react-calendar__tile--active {
  background: #6f6f6f;
  border-radius: 500px;
  color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f6f6f;
  color: white;
}
.react-calendar--selectRange .react-calendar__tile--hover {
  /* background-color: #e6e6e6; */
}

/* new */
.react-calendar {
  border-bottom: 1px solid #bbb;
}

.react-calendar__navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.react-calendar__navigation__label {
  font-family: 'Vietnam';
  font-size: 14;
  color: #404040;
  font-weight: 400;
  position: absolute;
  left: 15px;
}

.react-calendar__navigation__prev2-button {
  display: none;
}

.react-calendar__navigation__next2-button {
  display: none;
}

.react-calendar__month-view__days__day--neighboringMonth {
  opacity: 0;
}

.react-calendar__month-view__weekdays__weekday abbr {
  color: #6f6f6f;
  text-decoration: none;
  font-family: 'Vietnam';
  font-size: 12px;
  font-weight: 400;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__month-view__days__day {
  color: #404040;
  font-family: 'Vietnam';
  font-size: 14px;
  font-weight: 500;
}

.react-calendar__month-view__days__day--weekend {
  color: #404040;
  font-family: 'Vietnam';
  font-size: 14px;
  font-weight: 600;
}

.react-calendar__tile {
  transition-duration: 0.4s;
}

.react-calendar__tile--active:enabled:focus {
  background: #6f6f6f;
  border-radius: 500px;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #6f6f6f;
  border-radius: 500px;
  color: white;
}

.react-calendar__tile--hasActive {
  background: #6f6f6f;
  border-radius: 500px;
  color: white;
}

.react-calendar__tile--now {
  background: rgba(0, 0, 0, 0);
}

.react-calendar__month-view__days__day:hover,
.react-calendar__month-view__days__day:focus {
  border-radius: 500px;
  background: #6f6f6f;
  opacity: 1;
  color: white;
}

.react-calendar__navigation__prev-button {
  /* color: rgba(0,0,0,0);
  background: url(./arrow_left.svg);
  background-size: contain;
  height: 15px;
  width: 15px; */
}

.react-calendar__month-view__days__day {
}
