.calendar {
  &-container {
    height: 775px;
    width: 100%;
    background-color: white;
    border-radius: 10px;
    border: solid 1px rgba(218, 218, 218, 1);

    &-padding {
      padding: 10px 25px;
      padding-bottom: 0px;
    }
  }

  &-type {
    &-button {
      height: 40px;
      width: 66px;
      border-radius: 10px;
      cursor: pointer;
      background-color: white;
      border: solid 1px #7da5c2;

      &-disabled {
        @extend .calendar-type-button;
        border: none;
      }
    }
  }

  &-admin {
    &-button {
      height: 40px;
      border-radius: 10px;
      cursor: pointer;
      background-color: white;
      border: solid 1px #7da5c2;

      &-disabled {
        @extend .calendar-type-button;
        border: none;
      }
    }
  }

  &-month {
    &-arrow {
      height: 13px;
      width: 13px;
      object-fit: contain;
      object-position: center;
      cursor: pointer;

      &-reverse {
        @extend .calendar-month-arrow;
        transform: rotate(180deg);
      }
    }

    &-container {
      width: 120px;
      height: 45px;
    }
  }

  &-week {
    &-container {
      padding: 0px 15px;
      height: 45px;
    }

    &-graph {
      &-container {
        height: auto;
      }

      &-border {
        &-quarter {
          border-top: solid 0.5px #c8c8c8;
          border-right: solid 0.5px #c8c8c8;
          border-bottom: solid 0.5px #c8c8c8;
        }

        &-half {
          border-right: solid 0.5px #c8c8c8;
          border-bottom: solid 0.5px #c8c8c8;
        }
      }

      &-card {
        height: 52px;
        min-height: 33px;
        width: 5%;
        background: #e5edf3;
        position: absolute;
        left: 66px;
        top: 0%;
        outline: none;
        overflow: hidden;

        &:hover {
          border-radius: 10px;
        }
      }
    }

    &-strip {
      background: #d1f0d0;
      height: 48px;
      width: 100%;
    }

    &-time {
      &-container {
        height: 52px;
        width: 66px;
      }
    }

    &-day {
      height: 52px;
      flex: 1;
      display: flex;
      flex-direction: column;

      &-container {
        height: 100%;
        width: calc(100% - 66px);
        cursor: pointer;
        display: flex;
      }
    }

    &-info {
      height: 112px;
      width: 210px;
      background-color: white;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      padding: 10px 15px;

      &-container {
        height: 145px;
        width: 230px;
        display: flex;
        flex-direction: column;
        position: absolute;
        align-items: center;
        top: 100%;
        right: 25px;
        overflow: hidden;
      }

      &-arrow {
        height: 15px;
        width: 23.78px;
        margin-right: 25px;
        z-index: 1;
      }

      &-edit {
        height: 25px;
        width: 25px;
        object-fit: contain;
      }
    }
  }

  &-date {
    color: #5cb555;
    font-size: 14px;

    &-container {
      width: 100%;
    }

    &-column {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 14.25%;
      height: 46px;

      &-days {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 14.25%;
        height: 25px;
      }

      &-one {
        @extend .calendar-date-column;
      }

      &-two {
        @extend .calendar-date-column;
      }

      &-three {
        @extend .calendar-date-column;
      }

      &-four {
        @extend .calendar-date-column;
      }

      &-five {
        @extend .calendar-date-column;
      }

      &-six {
        @extend .calendar-date-column;
      }

      &-seven {
        @extend .calendar-date-column;
      }
    }

    &-state {
      height: 11px;
      width: 11px;
      background-color: #8dd5be;
      border-radius: 500px;
      position: absolute;
      top: calc(100% + 3px);
    }

    &-selected {
      height: 41px;
      width: 41px;
      border-radius: 41px;
      background: #5cb555;
      position: absolute;
    }
  }
}
