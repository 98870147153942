.error {
  &-container {
    height: calc(100vh - 65px);
    width: 100%;
    background: rgba(248, 248, 248, 1);
    position: relative;
  }

  &-border {
    background-color: #193e5d;

    &-bottom {
      @extend .error-border;
      height: 50px;
      width: 100%;
      position: absolute;
      bottom: 0%;
    }

    &-right {
      @extend .error-border;
      width: 50px;
      height: 85%;
      position: absolute;
      bottom: 0%;
      right: 0%;
      clip-path: polygon(0 0, 100% 5%, 100% 100%, 0% 100%);
    }
  }

  &-button {
    background-color: #193e5d;
    border-radius: 500px;
    height: 43px;
    cursor: pointer;
    margin-top: 25px;
  }

  &-img {
    height: 130px;
    width: 130px;
    object-fit: contain;
    object-position: center;
    animation-delay: 1000ms;
  }
}
