.row-details-dots {
  width: 15px;
  height: 15px;
}
.row-details:hover {
  background-color: aqua;
}
.row-details-popup {
  width: 185px;
  height: 165px;
  position: absolute;
  right: 8px;
  margin-top: 20px;
  background-image: url('../../../../../../assets/svg/icon/Combined Shape.svg');
  &-options {
    position: relative;
    margin-top: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    cursor: pointer;
    color: #000000;
  }
  &-options:hover {
    background: #e5edf3;
    color: #2f8217;
  }
  &-line {
    width: 100px;
    height: 0%;
    margin-top: 10px;

    border: 1px solid #aab7c4;
    background-color: #aab7c4;
  }
}
.documents {
  &-divider {
    height: 63px;
    width: 1px;
    background: #aab7c4;
    margin-left: 15px;
    margin-right: 15px;
  }

  &-container {
    height: calc(100vh - 65px);
    width: 100%;
    background: rgba(248, 248, 248, 1);
  }

  &-search {
    &-building {
      height: 45px;
      width: 156px;
      border-radius: 10px;
      border: solid 1px #2f8217;
      background: white;
    }
  }

  &-arrow-curve {
    height: 28px;
    width: 28px;
    object-fit: contain;
  }

  &-panel {
    width: 100%;
    height: calc(100vh - 205px);
    background: white;
    border-radius: 10px;
    border: solid 1px #d4d4d4;
    overflow: hidden;

    &-divider {
      border-right: solid 1px #aab7c4;
    }
  }

  &-tab {
    height: 50px;
    width: 100%;
    position: relative;
    cursor: pointer;

    &-arrow {
      position: absolute;
      right: 5px;
      height: 15px;
      width: 15px;
      object-fit: contain;
    }
  }

  &-file {
    &-name {
      width: 25%;
    }

    &-person {
      width: 25%;
    }

    &-label {
      width: 15%;
    }

    &-date {
      width: 25%;
    }

    &-menu {
      width: 10%;
      cursor: pointer;
      position: relative;
      z-index: 1;

      &-dot {
        height: 6px;
        width: 6px;
        background: #525252;
        border-radius: 6px;
        margin-top: 2px;
        margin-bottom: 2px;
      }

      &-modal {
        background: white;
        border-radius: 10px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
        height: 139px;
        width: calc(100% - 10px);
        z-index: 2;
        &-arrow {
          height: 15px;
          width: 23.78px;
          margin-right: 12.5px;
          z-index: 1;
        }

        &-container {
          position: absolute;
          top: 100%;
          right: -23px;
          width: 180px;
          height: 160px;
          z-index: 12;
          display: flex;
          flex-direction: column;
          align-items: center;
          outline: none;
        }

        &-button {
          height: 44px;
          width: 80%;
          z-index: 2px;
          cursor: pointer;
        }

        &-divider {
          border-bottom: solid 1px #aab7c4;
        }
      }
    }

    &-notif {
      width: 8px;
      height: 8px;
      border-radius: 8px;
      background: #5cb555;
      margin-right: 10px;
    }

    &-doc {
      height: 28px;
      width: 28px;
      object-fit: contain;
      margin-right: 15px;
    }
  }
}

.status {
  &-icon {
    width: 140px;
  }
  &-details {
    height: 45px;
    border: solid 1px #d3d3d3;
    border-radius: 3px;

    &-name {
      min-width: 129px;
      width: 12.5%;
      margin-right: 2px;

      // &-box {
      //     @extend .services-details-name;
      //     @extend .services-details;
      // }

      &-color {
        height: 100%;
        width: 7px;
        background: #b2d5ff;
        margin-right: 7px;
      }

      &-filter {
        // height: 800px;
        width: 210px;
        background-color: white;
        border-radius: 10px;
        box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
        padding: 5%;

        &-container {
          position: absolute;
          top: 100%;
          right: 50px;
          width: 220px;
          // height: 850px;
          z-index: 12;
          display: flex;
          flex-direction: column;
          align-items: center;
          outline: none;
        }

        &-arrow {
          height: 15px;
          width: 23.78px;
          margin-right: 12.5px;
          z-index: 1;
        }

        &-bubble {
          height: 12px;
          width: 12px;
          border-radius: 500px;
        }

        &-line {
          height: 2px;
          width: 138px;
          background: #aab7c4;
          margin: 10px 0px;
        }
      }
    }
  }
}

.PID {
  &-button {
    border: solid 1px #5cb555;
    background: #5cb555 !important;
    height: 20px;
    width: 20px;
    border-radius: 15px;
    overflow: hidden;
    cursor: pointer;

    &-check {
      height: 60%;
      width: 60%;
      object-fit: contain;
    }
  }
}

.row-detail-card {
  background-color: #ffffff;
  padding: 15px;
  border: 1px solid #dedede;
  border-radius: 10px;
  width: 100%;
  height: auto;
}

.row-detail-card-verticleLine {
  height: auto;
  width: 1px;
  background-color: #dedede;
}
