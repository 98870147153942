.step-two {
  &-description {
    font-style: italic;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #6f6f6f;
  }
  &-other-heading {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;

    color: #000000;
  }
  &-icon-size {
    width: 20px;
    height: 20px;
  }
}
