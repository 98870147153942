.message-mobile {
  &-container {
    height: calc(100vh - 65px);
    width: 100%;
    background-color: white;
  }

  &-search {
    &-container {
      width: 100%;
      height: 45px;
      border-radius: 10px;
      border: solid 1px #dcdcdc;
      background-color: white;
    }
  }

  &-explorer {
    height: calc(100vh - 175px);
    width: 100%;
    border: solid 1px #dcdcdc;
    background-color: white;
    border-radius: 10px;
    margin-top: 15px;

    &-nav {
      height: 87px;
      width: 100%;
      border-bottom: solid 1px #dcdcdc;
      overflow: hidden;
    }

    &-body {
      height: calc(100% - 87px);
      width: 100%;
      overflow-y: auto;
    }
  }

  &-modal {
    height: 100vh;
    width: 100vw;
    position: fixed;
    left: 0px;
    top: 0px;
    background-color: rgba(0, 0, 0, 0.45);
    z-index: 15;
    padding: 25px;

    &-image {
      width: 100%;
    }

    &-button {
      height: 100%;
      width: 49%;
      border-radius: 5px;
      cursor: pointer;
      background-color: #7da5c2;

      &-container {
        height: 45px;
        width: 100%;
        position: absolute;
        bottom: 25px;
        display: flex;
        justify-content: space-between;
        padding: 0px 25px;
      }

      &-cancel {
        @extend .message-mobile-modal-button;
        background-color: #c29a7d;
      }

      &-submit {
        @extend .message-mobile-modal-button;
      }
    }
  }
}
