.link-style {
  color: blue;
  text-decoration: underline;
}
.react-pdf__Document {
  line-height: 0;
}
.react-pdf__Page__textContent {
  height: 860px !important;
}
