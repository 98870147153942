.delete-confirm {
  &-yes-button {
    height: 42px;
    width: 100px;
    background-color: #e67b88;
    color: #ffffff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }
  &-no-button {
    height: 42px;
    width: 100px;
    background-color: #5cb555;
    color: #ffffff;
    border-radius: 10px;
    font-weight: 500;
    font-size: 14px;
    cursor: pointer;
  }
  &-view-popup {
    padding-top: 10px;
    padding-left: 5%;
    padding-right: 5%;
    padding-bottom: 5%;
  }
}
