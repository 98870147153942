.private-client {
  &-main-div {
    height: 100%;
    width: 100%;
    background-color: #ffffff;
    border-radius: 10px;
    padding: 10px 20px;
    border: 1px solid #dadada;
  }
  &-img-div {
    height: 100px;
    width: 100px;
    // border: 2px solid #000000;
    border-radius: 50%;
  }
  &-logo-img {
    height: 100%;
    width: 100%;
    object-fit: contain;
    border-radius: 50%;
  }
  &-button-save {
    height: 40px;
    width: 77px;
    color: #ffffff;
    background-color: #009da0;
    border-radius: 6px;
    cursor: pointer;
  }
  &-check-icon {
    height: 11px;
    width: 13px;
    margin-right: 5px;
  }
  &-div-save {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    width: 100%;
  }
  &-icon-button {
    height: 35px;
    width: 35px;
    object-fit: contain;
    cursor: pointer;
  }
  &-div-icon {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }
  &-display-none {
    display: none;
  }
}
