.info-tab-heading {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 20px;

  color: #4a4a4a;
}
.info-tab-details {
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;

  color: #000000;
}
.info-tab-save {
  height: 40px;
  padding: 0px 15px;
  border-radius: 5px;
  background-color: #7da5c2;
  cursor: pointer;

  &-container {
    margin-top: 10px;
  }
}

.info-tab-datum {
  &-dropdown {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
    padding: 10px 15px;
    display: flex;
    position: absolute;
    left: 0px;
    top: calc(100% - 15px);
  }

  &-line {
    height: 100%;
    width: 2px;
    background: #dcdcdc;
    margin: 0px 15px;
  }
}

.info-tab-input {
  height: 35px;
  width: 100%;
  border: solid 1px #dcdcdc;
  border-radius: 7px;
  background-color: white;
  padding: 0px 15px;
}

.description-textarea {
  resize: none;
  height: 100px;
}

.description-save {
  width: 100px;
}
