.epb-service {
  &-grid-size {
    height: 100vh;
    min-height: 100%;
    overflow-y: auto;
  }
  &-menu-icon {
    width: 40px;
    height: 40px;
    padding: 10px;
    background: #ffffff;
    border: 1px solid #dcdcdc;
    border-radius: 10px;
  }
  &-tab-selected {
    display: inline-block;
    width: auto;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 10px;
    margin-top: 7px;
    color: #000000;
    border-bottom: 2px solid #7da5c2;
  }
  &-tab-unSelected {
    width: 95px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #9b9b9b;
  }
  &-tab-disable {
    width: 95px;
    height: 20px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #dcdcdc;
  }
  &-button {
    width: 96px;
    height: 42px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    background: #009da0;
    border: 1px solid #7da5c2;
    border-radius: 10px;
  }
  &-checkbox {
    &-outline {
      width: 14px;
      height: 14px;
      border: 1.5px solid #7da5c2;
    }
    &-filled {
      width: 14px;
      height: 14px;
      background: #7da5c2;
      //   padding: 5px;
      &-image {
        width: 10px;
        height: 10px;
      }
    }
  }

  &-Category-heading {
    width: auto;
    height: 33px;
    background: #dde6ec;
    &-arrow-size {
      width: 10px;
      height: 10px;
      margin-left: 10px;
    }
    &-image-size {
      width: 19px;
      height: 19px;
      align-self: center;
      cursor: pointer;
    }
    &-label {
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 23px;

      color: #000000;
    }
  }
  &-insulation {
    &-start-line {
      width: 7px;
      height: 100%;
      margin-left: 90%;
      margin-top: 1%;
      background: #dde6ec;
    }
    &-category-name {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #000000;
    }
  }
}

@media only screen and (max-width: 900px) {
  .epb-service {
    &-insulation {
      &-start-line {
        width: 0px;
        height: 0px;
      }
    }
  }
}
