.actions-information-tabs {
  width: 80%;
  height: 45px;
  background: #ffffff;
  border: 1px solid #5cb555;
  border-radius: 10px;
  overflow: hidden;
}
.actions-information-tab-buttons-size {
  width: 172px;
  height: 45px;
}
.actions-information-tab-buttons-image-size {
  width: 16px;
  height: 15px;
}
.actions-information-upload-file {
  width: 178px;
  font-size: 12px;
  line-height: 18px;
  text-align: right;
  align-self: center;
  color: #6f6f6f;
}
.actions-information-button {
  width: 103px;
  height: 40px;
  color: #4a4a4a;
  margin-left: 60px;
  cursor: pointer;
}
.actions-information-document-headers {
  font-size: 14px;
  color: #9b9b9b;
  &-icon {
    width: 8px;
    height: 4px;
    margin-top: 10px;
  }
}
.tab-button-style {
  width: 100%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #9b9b9b;
  background: #ffffff;
  border-radius: 10px;
  cursor: pointer;
}
.selected-tab-button-style {
  width: 100%;
  height: 44px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  background: #5cb555;
  border-radius: 10px;
  cursor: pointer;
}
.white-image {
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(245deg) brightness(150%)
    contrast(103%);
}
.grey-image {
  filter: invert(56%) sepia(2%) saturate(18%) hue-rotate(337deg)
    brightness(110%) contrast(89%);
}

@media only screen and (max-width: 960px) {
  .actions-information-tabs {
    width: 100%;
  }
}
