.step-one {
  &-heading-label {
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    color: #4a4a4a;
  }
  &-data-label {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #000000;
  }
}
