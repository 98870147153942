.ventilation-service {
  &-green-button {
    height: 42px;
    width: 96px;
    border-radius: 6px;
    background-color: #7cbf47;
    color: #ffffff;
    cursor: pointer;
  }
  &-tab-div {
    width: auto;
    height: 25px;
    margin-top: 15px;
    font-size: 14px;
    margin-right: 30px;
  }
  &-tab-item {
    width: 100%;
    height: 100%;
    color: #000000;
  }
  &-selected-border {
    border-bottom: 1px solid #7da5c2;
  }
  &-button-icon {
    height: 40px;
    width: 35px;
  }
  &-button-upload {
    display: flex;
    align-items: center;
    border-radius: 10px;
    height: 42px;
    min-width: 160px;
    background-color: #7da5c2;
    color: #ffffff;
    cursor: pointer;
  }
  &-file-input {
    display: none;
  }
  &-blue-strip {
    width: 100%;
    height: 33px;
    background-color: #dde6ec;
    color: #000000;
  }
  &-button-div {
    width: 15%;
    min-width: 160px;
    height: 100%;
  }
  &-file-div {
    width: 60%;
    display: flex;
    align-items: center;
    margin-left: 30px;
  }
}
