.project-application-contactinfo-edit {
  line-height: 1.5rem;
  border-radius: 10px;
  padding: 8px 6px;
  background-color: #ffffff;
  color: #000000;
  font-size: 14px;
  width: 100%;
  border: 1px solid #dcdcdc;
}

.project-application-contactinfo-dropdown {
  height: 125px;
  width: 100%;
  overflow-y: auto;

  &-default {
    height: 45px;
    width: 100%;
    padding: 10px;
  }

  &-container {
    width: 345px;
    border: solid 1px #dadada;
    background-color: white;
    border-radius: 10px;
    cursor: pointer;
    margin-top: 25px;
    overflow: hidden;
  }

  &-arrow {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }

  &-item {
    height: 40px;
    width: 100%;
    padding: 0px 10px;
    justify-content: center;

    &:hover {
      background-color: #cbffd0;
    }
  }
}
