.add-contact-person {
  &-close {
    height: 25px;
    width: 25px;
    object-fit: contain;
    cursor: pointer;
  }

  &-cancel {
    height: 40px;
    width: 77px;
    border-radius: 6px;
    background-color: #a00300;
    margin-right: 15px;
    cursor: pointer;
  }

  &-select {
    height: 40px;
    width: 100%;
    background: white;
    border-radius: solid 1px #dadada;
    border-radius: 10px;
    margin-top: 10px;
    outline: none;
  }
}
