.email-modal {
  height: 90%;
  width: 90%;
  background-color: white;
  border-radius: 10px;
  padding: 25px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  overflow-y: auto;

  &-container {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 99999;
  }

  &-close {
    height: 18px;
    width: 18px;
    object-fit: contain;
    cursor: pointer;
  }

  &-field {
    height: 40px;
    width: 100%;

    &-container {
      width: 100%;
      border-radius: 10px;
      border: solid 1px #dcdcdc;
      padding: 0px 15px;
    }

    &-main-container {
      height: 270px;
      width: 100%;
      overflow-y: auto;
    }

    &-arrow {
      height: 15px;
      width: 15px;
      object-fit: contain;
      cursor: pointer;
    }
  }

  &-selected {
    border-radius: 10px;
    height: 40px;
    background: #e5edf3;
    padding: 0px 15px;

    &-dropdown {
      width: 350px;
      border-radius: 10px;
      background: white;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
      position: absolute;
      top: calc(100% + 15px);
      left: 0%;
      padding: 15px;
      display: flex;
      flex-direction: column;
      height: auto;
      z-index: 99999;
      overflow-y: auto;

      &-button {
        padding: 0px 15px;
        height: 35px;
        border-radius: 7px;
        background: #7da5c2;
        cursor: pointer;
        width: fit-content;
        margin-top: 15px;
      }
    }

    &-list {
      display: flex;
      width: 100%;
      flex-wrap: wrap;
    }
  }

  &-button {
    height: 40px;
    border-radius: 10px;
    border: solid 1px #5cb555;
    padding: 0px 15px;
    cursor: pointer;
  }

  &-editor {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #dcdcdc;
    overflow: hidden;

    & span,
    p,
    h1,
    h2,
    h3,
    h4,
    h5 {
      color: black;
    }
  }

  &-send {
    height: 40px;
    width: 300px;
    border-radius: 10px;
    background-color: #5cb555;
    margin-top: 10px;
    cursor: pointer;
  }

  &-close {
    height: 14px;
    width: 14px;
    object-fit: contain;
    margin-left: 10px;
    cursor: pointer;
  }

  &-add {
    height: 14px;
    width: 14px;
    object-fit: contain;
  }
}

.editorClassName {
  padding: 0px 15px;
  height: 90%;

  & span {
    display: inline;
  }

  & div {
    margin: 0px;
    line-height: 1.2;
  }
}

.wrapperClassName {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@media only screen and (max-width: 600px) {
  .toolbarHide {
    display: none;
  }

  .email-modal {
    height: 95%;
    width: 95%;

    &-selected {
      margin-bottom: 5px;
      width: fit-content;

      &-list {
        flex-direction: column;
      }

      &-dropdown {
        width: 300px;
      }
    }
  }
}
