.company-clients {
  &-main-div {
    padding-top: 15px;
    padding-left: 20px;
    padding-right: 20px;
    width: 100%;
    height: calc(100vh - 65px);
    background-color: rgba(248, 248, 248, 1);
  }
  &-card-container {
    margin-top: 10px;
    margin-bottom: 10px;
    height: calc(100vh - 225px);
    overflow-y: auto;
  }
  &-card-main {
    min-height: 50px;
    padding-left: 10px;
    max-height: 50px;
  }
  &-header-main {
    padding-left: 10px;
    margin-top: 20px;
    height: 25px;
  }
  &-header-item {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
    align-items: center;
    cursor: pointer;
  }
  &-search-bar {
    display: flex;
    flex-direction: row;
    height: 42px;
    width: 90%;
    padding: 2%;
    background-color: #ffffff;
    border-radius: 10px;
    border: 1px solid #dcdcdc;
    align-items: center;
  }
  &-search-icon {
    height: 20px;
    width: 20px;
    margin-left: 5px;
  }
  &-verticle-line {
    height: 50px;
    width: 2px;
    background-color: #aab7c4;
  }
  &-word-break {
    width: 95%;
    overflow-wrap: break-word;
  }
  &-icon-button {
    height: 35px;
    width: 35px;
    cursor: pointer;
  }
}
