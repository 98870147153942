.label-view-size {
  width: 664px;
  height: 600px;
  background: #ffffff;
  border-radius: 10px;
  overflow: auto;
}
.label {
  &-container-size {
    width: 630px;
    height: 58px;
    padding: 20px;
  }
  &-user-icon {
    width: 30px;
    height: 30px;
    // margin-right: 8px;
  }
  &-title {
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 26px;
    color: #000000;
  }
  &-cross-icon {
    width: 15px;
    height: 15px;
  }
  &-image {
    width: 55px;
    height: 55px;
    border-radius: 50px;
  }
  &-data-view {
    height: 66%;
    overflow-x: hidden;
    overflow-y: auto;
    margin: 20px;
  }
  &-radio-outline {
    width: 19px;
    height: 19px;
    border: 1.5px solid #5cb555;
    border-radius: 10px;
  }
  &-radio-filled {
    width: 19px;
    height: 19px;
    background: #5cb555;
    border-radius: 10px;
  }
  &-info {
    &-modal {
      position: absolute;
      border-radius: 5px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
      background: #ffffff;
      left: 68px;
      padding: 10px;
      display: flex;
      align-content: center;
      z-index: 10px;
      margin-top: 5px;
    }
    &-arrow {
      height: 15px;
      width: 23.78px;
      margin-top: 5px;
      z-index: 1;
      left: 50px;
      transform: rotate(270deg);
    }
  }
}

.word-break {
  overflow-wrap: break-word;
}

@media only screen and (max-width: 600px) {
  .label {
    &-data-view {
      height: 60%;
      margin: 10px;
    }
  }
}
