.loginpage-detail-container {
  background-color: #ffffff;
  height: 100%;
  min-height: 100vh;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.loginpage-detail-container-mobile {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: center;
  //background-image: url('../../assets/svg/Bip color.svg');
  background-image: linear-gradient(white, #d6f5d6);
  background-size: cover;
  overflow: auto;
}

.loginpage {
  &-logo {
    //height: 140px;
    width: 250px;
  }

  &-center-image {
    width: 70%;
  }

  &-background-image {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100vh;
  }
  &-background-image-mobile {
    // position: absolute;
    // left: 0;
    width: auto;
    height: 100vh;
  }
  &-mobile-logo {
    top: 0px;
    object-position: left;
  }
  &-welcome-div {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  &-end-text-div {
    margin-bottom: 10px;
    margin-top: 25px;
  }
  &-grid-container {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    // justify-content: center;
    align-items: center;
    //background-image: url('../../assets/svg/Bip color.svg');
    background-image: linear-gradient(white, #d6f5d6);
    background-size: cover;
  }
  &-justify-start {
    display: flex;
    justify-content: flex-start;
  }
  &-justify-end {
    display: flex;
    justify-content: flex-end;
  }
  &-arrow-right {
    height: 20px;
    margin-top: 2px;
    margin-right: 20px;
    right: 0px;
  }
}

@media only screen and (max-width: 1200px) {
  .loginpage-detail-container {
    background-color: #ffffff;
    width: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 10px;
    min-height: auto;
    padding: 20px;
    min-height: auto;
    opacity: 0.9;
    height: auto;
    position: relative;
  }
}

@media only screen and (max-width: 600px) {
  .loginpage-detail-container {
    height: auto;
  }
  .loginpage {
    &-justify-start {
      display: flex;
      justify-content: center;
    }
    &-justify-end {
      display: flex;
      justify-content: center;
    }

    &-welcome-div {
      margin-top: 15px;
      margin-bottom: 15px;
    }
  }
}
